import React, { useState } from "react"
import Layout, { MyLocationEnum } from "../components/Layout"
import SEO from "../components/Seo"
import styled from 'styled-components'
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

const Leidbeiningar = ({ location }) => {
  const data = useStaticQuery(
    graphql`
      query Leidbeiningar {
        file(relativePath: { eq: "leidbeiningar.png" }) {
          childImageSharp {
            fluid( maxWidth: 2000, quality: 80 ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )
  return(
    <Layout location={location} myLocation={MyLocationEnum._File}>
      <SEO title="Leidbeiningar" />
      <Container>
        <MyGatsbyImageWrapper
          durationFadeIn={100}
          fluid={data.file.childImageSharp.fluid} 
          objectPosition="top center"
        />
      </Container>
    </Layout>
  )
}


const Container = styled.div`
	position: relative;
	overflow: hidden;
	width: 100vw;
  height: 100vh;
  pointer-events: auto;
  background: #262262; 
`

const MyGatsbyImageWrapper = styled(Img)`
    width: 900px;
    margin: 0 auto;

    @media (max-width: 900px) { 
      width: 100%;
    }
`

export default Leidbeiningar
